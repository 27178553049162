import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "./Home";
import NotFound from "./NotFound";
import ImageDetail from "./ImageDetail";
import Why from "./Why";
import About from "./About";

function App() {
  const router = createBrowserRouter([
    {
      path: "/warum",
      element: <Why />,
    },
    {
      path: "/ueber",
      element: <About />,
    },
    {
      path: "/:categoryTag?",
      element: <Home />,
      errorElement: <NotFound></NotFound>,
    },
    {
      path: "view/*",
      element: <ImageDetail />,
    },
  ]);
  return <RouterProvider router={router} />;
}

export default App;
