import { Button, Flex, Heading, VStack } from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";
import React, { } from "react";
import { Helmet } from "react-helmet";

const Why = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`usmalbilder.ch | positive Auswirkungen des Ausmalens`}</title>
        <meta name="description" content="" />
      </Helmet>
      <Box bg="#e7e7e7" minHeight="100vh" width="100%" p={10}>

        <VStack spacing={2} padding={4} align="start">
          <Heading as="h1">Warum Ausmalbilder gut sind</Heading>
          <Box paddingTop={10} paddingBottom={10}>

            <p style={{ marginBottom: '10px' }}>
              <strong>Die positiven Auswirkungen des Ausmalens auf die mentale Gesundheit sind vielfältig</strong>
            </p>
            <p style={{ marginBottom: '10px' }}>
              Das Ausmalen von Bildern ist nicht nur eine unterhaltsame Aktivität, sondern hat auch nachweislich positive Auswirkungen auf die mentale Gesundheit von Kindern und Erwachsenen. Hier sind einige Gründe, warum das Ausmalen förderlich ist:
            </p>
            <ul style={{ marginBottom: '10px' }}>
              <li><strong>Konzentration und Fokussierung:</strong> Beim Ausmalen werden die Sinne aktiviert, wodurch die Konzentration und Fokussierung verbessert werden.</li>
              <li><strong>Stressabbau:</strong> Das Ausmalen wirkt stressreduzierend und fördert eine entspannte Atmosphäre.</li>
              <li><strong>Kreativer Ausdruck:</strong> Durch das Ausmalen können Kinder und Erwachsene ihre kreative Seite ausdrücken und ihre Vorstellungskraft entwickeln.</li>
              <li><strong>Entspannung und Achtsamkeit:</strong> Die meditative Natur des Ausmalens trägt zur Entspannung und Achtsamkeit bei, was sich positiv auf das Wohlbefinden auswirkt.</li>
            </ul>


          </Box>
          <Flex>
            <Button flex={1} colorScheme="green" href="/" as="a">
              Ausmalbilder finden
            </Button>
          </Flex>
        </VStack>
      </Box>
    </>
  );
};

export default Why;