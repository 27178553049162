import { Button, Flex, Heading, VStack } from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";
import React, {  } from "react";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`usmalbilder.ch | Über das Projekt`}</title>
        <meta name="description" content="" />
      </Helmet>
      <Box bg="#e7e7e7" minHeight="100vh" width="100%" p={10}>

        <VStack spacing={2} padding={4} align="start">
          <Heading as="h1">Über usmalbilder.ch</Heading>
          <Box paddingTop={10} paddingBottom={10}>
            <p style={{ marginBottom: '10px' }}>
              <strong>Die Verwendung von künstlicher Intelligenz für Ausmalseiten</strong>
            </p>
            <p style={{ marginBottom: '10px' }}>
              Der Titel mag kühn sein, aber wir nutzen generative KI, um Ausmalseiten für Kinder und Erwachsene zu erstellen.
            </p>
            <p style={{ marginBottom: '10px' }}>
              Wir haben beschlossen, ein kleines Familiengeschäft zu starten. Wir hatten die Idee, eine Website einzurichten, auf der die Leute Ausmalseiten herunterladen können.
            </p>
            <p style={{ marginBottom: '10px' }}>
              Es ist eine grossartige Gelegenheit, meinen Kindern beizubringen, wie man mit KI-Systemen interagiert und sie effektiv nutzen kann; und nebenbei lernen sie die Grundlagen, um später ein eigenes Geschäft zu starten.
            </p>
            <p style={{ marginBottom: '10px' }}>
              Alle Details zum Projekt sind auf <a href="https://www.christianmenz.ch">www.christianmenz.ch</a> zu finden.
            </p>
          </Box>

          <Flex>

            <Button flex={1} colorScheme="green" href="/" as="a">
              Ausmalbilder finden
            </Button>
          </Flex>
        </VStack>

      </Box>
    </>
  );
};

export default About;