import styles from "./Home.module.css";

import { Card, CardBody, Grid, Image, Text } from "@chakra-ui/react";
import { Heading, VStack } from "@chakra-ui/react";
import { Box, SimpleGrid } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link, NavLink, useParams } from "react-router-dom";

import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";

function Home() {
  const [data, setData] = useState({});
  const { categoryTag = "unicorn" } = useParams();
  const category = data.categories
    ? data.categories.find((cat) => cat.tag === categoryTag)
    : null;

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("/db.json");
      const json = await response.json();
      setData(json);
    };
    fetchData();
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/", title: "Home Page" });
  }, []);

  function handleImageOpen(image) {
    ReactGA.event({
      category: "Image",
      action: "open_image",
      label: image.downloadUrl,
    });
  }

  const filteredImages = data.images
    ? data.images.filter((image) => image.tag === categoryTag)
    : [];

  return (
    <>
      <Helmet>
        <title>{`usmalbilder.ch | kostenlose Ausmalbilder und Malvorlagen zum Thema ${category && category.name}`}</title>
        <meta name="description" content={category && category.description} />
      </Helmet>
      <Box bg="#e7e7e7" minHeight="100vh" width="100%">
        <VStack>
          <Heading m={10} as="h1">usmalbilder.ch | {category && category.name}</Heading>
          <Grid
            templateColumns={{ sm: "1fr", md: "250px 1fr" }}
            gap={4}
            width="100%"
          >
            <Box p={4}>
              <VStack spacing={2} align="start">
                <Text fontSize="xl" fontWeight="bold">
                  Kategorien
                </Text>
                {data.categories &&
                  data.categories
                    .filter((category) => !category.inactive)
                    .map((category, index) => (
                      <NavLink
                        key={index}
                        to={`/${category.tag}`}
                        className={() =>
                          category.tag === categoryTag ? styles.activeNavLink : ""
                        }
                      >
                        <Text>{category.name}</Text>
                      </NavLink>
                    ))}
                <Text fontSize="xl" fontWeight="bold">
                  Weiteres
                </Text>
                <NavLink
                  to={`/warum`}
                >
                  <Text>Warum Ausmalbilder gut sind</Text>
                </NavLink>

                <NavLink
                  to={`/ueber`}
                >
                  <Text>Über usmalbilder.ch</Text>
                </NavLink>
              </VStack>
            </Box>
            <Box>
              <Box>
                <Heading as="h2" size="md" paddingLeft={10} paddingRight={10}>
                  {category && category.description}
                </Heading>
              </Box>
              {filteredImages && filteredImages.length === 0 && (
                <Box padding={50}>
                  <Text>Noch keine Bilder, schau später nochmals vorbei!</Text>
                </Box>
              )}
              <SimpleGrid
                columns={{ lg: 4, md: 3, sm: 1 }}
                spacing={10}
                padding={10}
              >
                {filteredImages &&
                  filteredImages.map((image, index) => (
                    <Box key={index}>
                      <Card maxW="200px">
                        <CardBody margin={0} padding={0}>
                          <Link
                            to={`/view/${image.downloadUrl.replace('.png', '')}`}
                            onClick={() => handleImageOpen(image)}
                          >
                            <Image
                              src={`/${image.thumbnailUrl}`}
                              borderRadius="lg"
                              className={styles.colorPrintImage}
                              title={image.title}
                              alt={image.description}
                            />
                          </Link>
                        </CardBody>
                      </Card>
                    </Box>
                  ))}
              </SimpleGrid>
            </Box>
          </Grid>
        </VStack>
      </Box>
    </>
  );
}

export default Home;
