import { Button, Flex, Heading, Image, VStack } from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";

function ImageDetail() {
  const { "*": downloadPath } = useParams();
  const downloadUrl = downloadPath + '.png';
  const [image, setImage] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("/db.json");
      const json = await response.json();
      const image = json.images.find(
        (image) => image.downloadUrl === downloadUrl
      );
      setImage(image);
    };
    fetchData();
  }, [downloadUrl]);

  function handleImageDownload(image) {
    ReactGA.event({
      category: "Image",
      action: "download_image",
      label: image.downloadUrl,
    });
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`usmalbilder.ch | kostenloses Ausmalbild ${image && image.title}`}</title>
        <meta name="description" content={image && image.description} />
      </Helmet>
      <Box bg="#e7e7e7" minHeight="100vh" width="100%" p={10}>
        {image && (
          <VStack spacing={2} padding={4} align="start">
            <Heading as="h1">{image.title}</Heading>
            <Box paddingTop={10} paddingBottom={10}>
              <Heading as="h2" size="md">
                {image.description}
              </Heading>
            </Box>
            <Flex direction={{ base: 'column', md: 'row' }}>
              <Button
                flex={1}
                minHeight="50px"
                colorScheme="blue"
                mb={{ base: 4, md: 0 }}
                marginRight={{ base: 0, md: 5 }}
                href={`/${image.downloadUrl}`}
                as="a"
                target="_blank"
                onClick={() => handleImageDownload(image)}
              >
                Jetzt Ausmalbild ausdrucken
              </Button>
              <Button
                flex={1}
                minHeight="50px"
                colorScheme="green"
                href="/"
                as="a"
              >
                Mehr Ausmalbilder finden
              </Button>
            </Flex>
            <Image src={`/${image.downloadUrl}`} borderRadius="lg" width={500} />
          </VStack>
        )}
      </Box>
    </>
  );
}

export default ImageDetail;
