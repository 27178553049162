
import { Text } from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";
import React from "react";


function NotFound() {
  return (
    <Box bg="#e7e7e7" minHeight="100vh" width="100%">
      <Text>Not found!</Text>
    </Box>
  );
}

export default NotFound;
